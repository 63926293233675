<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-extract :current="2"></sidebar-extract>

          <div class="page__content">
            <h1 class="page__title">Trânsferencias</h1>
            <p class="page__subtitle">
              Digite a quantidade que deseja transferir para sua conta.
            </p>

            <form class="transfer__from">
              <div class="transfer__flex">
                <div class="transfer__col">
                  <label for="price">Informe o valor</label>

                  <r-input
                    type="money"
                    name="your-value"
                    input-class="input-money-transaction"
                    id="value"
                    placeholder="00,00"
                    v-model="form.value"
                  />
                  <p>
                    O valor será creditado em sua conta corrente em até 20 dias
                    úteis.
                  </p>
                </div>

                <div class="transfer__col">
                  <div class="transfer__accounts--header">
                    <span>Selecione a conta</span>
                    <a
                      href="#"
                      @click.prevent.stop="openModal(item)"
                      title="Gerenciar contas"
                      >Gerenciar contas</a
                    >
                  </div>

                  <div class="banks__list">
                    <div
                      @click="form.account = bank.id"
                      v-for="bank in banks"
                      :key="bank.id"
                      class="banks__item"
                    >
                      <input
                        type="radio"
                        value="itau"
                        name="your-bank"
                        class="input-radio-bank"
                        :id="`bank_${bank.id}`"
                      />

                      <label :for="`bank_${bank.id}`">
                        <img
                          class="banks__item--brand"
                          src="/img/banks/logo-banco-default.webp"
                          :alt="bank.name"
                        />

                        <div class="banks__item--content">
                          <span>Agência: {{ bank.agency }}</span>
                          <span>
                            Conta
                            {{ bank.type == 1 ? 'Corrente' : 'Poupança' }}:
                            {{ bank.number }}
                          </span>
                          <span>
                            <strong>{{ bank.name }}</strong>
                          </span>
                          <span>
                            <strong>CNPJ:</strong> {{ bank.cpf_cnpj }}
                          </span>
                          <div class="transfer__accounts--header">
                            <a
                              href="#"
                              @click.prevent.stop="openEditModal(bank)"
                              title="Editar conta"
                              >Editar conta</a
                            >
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="donation__footer">
                    <a
                      class="button button__medium button__primary"
                      href="#"
                      @click.prevent.stop="submit()"
                      title="Transferir"
                    >
                      {{ loadingForm ? 'Transferindo...' : 'Transferir' }}
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <modal-info
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
      @reload-list="getBanks"
      :selectedAccount.sync="selectedAccount"
    ></modal-info
    >>
  </main>
</template>
<script>
import swal from 'sweetalert'
import { mapGetters, mapActions } from 'vuex'

const defaultForm = { account: '', value: 0.0, iduser: '' }

export default {
  data: () => ({
    modal: false,
    banks: [],
    form: {
      ...defaultForm
    },
    selectedAccount: {},
    error: null,
    load: true,
    loadingForm: false
  }),
  props: {
    current: Boolean
  },
  components: {
    sidebarExtract: () => import('@/components/sidebar-extracts'),
    modalInfo: () => import('./components/modal-info-account'),
    RInput: () => import('@/components/input.vue')
  },
  methods: {
    ...mapActions('balance', ['getBalance']),
    async getBanks () {
      this.load = true
      this.error = null
      try {
        const url = 'financial/getAccounts'
        const userId = { iduser: this.user.id }
        const { data } = await this.$http.post(url, userId)
        if (data.data.length) this.banks = data.data
      } finally {
        this.load = false
      }
    },
    openModal (item) {
      this.selectedEntity = item
      this.modal = true
    },
    openEditModal (item) {
      if (!item) return
      const itemToEdit = { ...item }
      console.log(itemToEdit.number)
      this.selectedAccount = itemToEdit
      this.modal = true
    },

    async submit () {
      if (this.loadingForm) return
      if (!this.form.account || !this.form.value) {
        return swal({
          title: 'Aviso!',
          text: 'Selecione o Banco e insira um valor maior que zero.',
          icon: 'error'
        })
      }

      this.loadingForm = true
      try {
        this.form.iduser = this.user.id
        const url = 'financial/storeTransferUser'
        const { data, status } = await this.$http.post(url, this.form)

        if (status === 200 && data.status === 'success') {
          swal({
            title: 'Sucesso!',
            icon: 'success',
            text: 'Transferência realizada com sucesso.',
            buttons: false,
            timer: 2000
          })
          this.form = { ...defaultForm }
          this.getBalance()
          this.clearSelected()
          return
        }
        swal({
          title: 'Ooops!',
          icon: 'error',
          text: 'Erro ao realizar transferência.',
          buttons: false,
          timer: 2000
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          icon: 'error',
          text: 'Erro ao realizar transferência.',
          buttons: false,
          timer: 2000
        })
      } finally {
        this.loadingForm = false
      }
    },
    clearSelected () {
      setTimeout(() => {
        const docs = document.querySelectorAll('.input-radio-bank')
        docs.forEach(doc => {
          doc.checked = false
        })
      }, 100)
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  mounted () {
    this.getBanks()
  }
}
</script>

<style></style>
